import React from "react"

// import "./header.scss"

const Footer = () => (
    <footer>
        <div className="container-fluid footer">
            <div className="row bg-dark text-white d-flex">
                <p className="flex-fill">
                    © Dr. B. P. Agrawal Shiksha Niketan 2020
              </p>
            </div>
            <div className="row bg-dark text-white d-flex">
                <p className="copyright flex-fill">
                    Except as permitted by the copyright law applicable to you, you may not reproduce or communicate any of the content on this website, including files downloadable from this website, without the permission of the copyright owner.
              </p>
            </div>
        </div>
    </footer>
)

export default Footer