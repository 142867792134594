import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import {Link} from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import {Navbar, Nav, NavDropdown} from "react-bootstrap"

// import "./header.scss"

const Header = ({siteTitle}) => {
//   const data = useStaticQuery(graphql`
//   query {  
//       logoql: file(relativePath: {eq: "logo-1.jpeg"}) {
//           childImageSharp {
//               fixed(width: 199, height: 175) {
//                   ...GatsbyImageSharpFixed
//               }
//           }
//       }
//     }
   
// `)

const data = useStaticQuery(graphql`
query {

    logoql: file(relativePath: {eq: "header/dbpasn-logo-1.png"}) {
        childImageSharp {
            fixed(width: 200) {
                ...GatsbyImageSharpFixed
            }
        }
    }

    logosmallql: file(relativePath: {eq: "header/dbpasn-logo-1-small.png"}) {
      childImageSharp {
          fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
          }
      }
  }

    nameql: file(relativePath: {eq: "header/dbpasn-name-1.png"}) {
        childImageSharp {
            fixed(width: 400) {
                ...GatsbyImageSharpFixed
            }
        }
    }

    namesmallql: file(relativePath: {eq: "header/dbpasn-name-1-small.png"}) {
      childImageSharp {
          fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
          }
      }
  }

    galleryql: file(relativePath: {eq: "header/dbpasn-gallery-1.png"}) {
        childImageSharp {
            fixed(width: 200) {
                ...GatsbyImageSharpFixed
            }
        }
    }

    gallerysmallql: file(relativePath: {eq: "header/dbpasn-gallery-1-small.png"}) {
      childImageSharp {
          fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
          }
      }
  }

    blogsql: file(relativePath: {eq: "header/dbpasn-blogs-1.png"}) {
        childImageSharp {
            fixed(width: 200) {
                ...GatsbyImageSharpFixed
            }
        }
    }

    blogssmallql: file(relativePath: {eq: "header/dbpasn-blogs-1-small.png"}) {
      childImageSharp {
          fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
          }
      }
  }
}
`)


  return <header> 
    <div className="container d-none d-lg-block" >
      <div className="row">
      <div className="col-2">
      <Link to="/"><Img  fixed={data.logoql.childImageSharp.fixed}/></Link>
      </div>
      <div className="col-6">
      <Link to="/"><Img  fixed={data.nameql.childImageSharp.fixed}/></Link>
      </div>
      <div className="col-2">
      <Link to="/photo-gallery"><Img  fixed={data.galleryql.childImageSharp.fixed}/></Link>
      </div>
      <div className="col-2">
      <Link to="/blogs"><Img  fixed={data.blogsql.childImageSharp.fixed}/></Link>
      </div>
    </div>
    </div>
    <div className="container d-none" >
      <div className="row">
      <div className="col-2">
      <Link to="/"><Img  fluid={data.logosmallql.childImageSharp.fluid}/></Link>
      </div>
      <div className="col-6">
      <Link to="/"><Img  fluid={data.namesmallql.childImageSharp.fluid}/></Link>
      </div>
      <div className="col-2">
      <Link to="/photo-gallery"><Img  fluid={data.gallerysmallql.childImageSharp.fluid}/></Link>
      </div>
      <div className="col-2">
      <Link to="/blogs"><Img  fluid={data.blogssmallql.childImageSharp.fluid}/></Link>
      </div>
    </div>
    </div>
    {/* <hr className="header-ruler"/> */}
    <div className="container">
    <Navbar sticky="top" expand="md" className="navbar-dark bg-primary">      
      <Navbar.Toggle aria-controls="navbarResponsive" />
      <Navbar.Collapse id="navbarResponsive">
        <Nav className="ml-auto">
          <NavDropdown title="Who We Are" id="collasible-nav-dropdown">
            <NavDropdown.Item href="/about-trust">About Trust</NavDropdown.Item>
            <NavDropdown.Item href="/">About DBPASN</NavDropdown.Item>
            <NavDropdown.Item href="/our-mission">Our Mission</NavDropdown.Item>
            <NavDropdown.Item href="/our-vision">Our Vision</NavDropdown.Item>
            <NavDropdown.Item href="/our-motto">Our Motto</NavDropdown.Item>
            {/* <NavDropdown title="Messages" id="collasible-nav-dropdown"> */}
              <NavDropdown.Item href="/chairmans-message">Chairman's Message</NavDropdown.Item>
              <NavDropdown.Item href="/directors-message">Director's Message</NavDropdown.Item>
              <NavDropdown.Item href="/principals-message">Principal's Message</NavDropdown.Item>
            {/* </NavDropdown> */}
          </NavDropdown>
          <NavDropdown title="Academics" id="collasible-nav-dropdown"> 
            <NavDropdown.Item href="/details-of-teachers">Details of Teachers</NavDropdown.Item>
            <NavDropdown.Item href="/details-of-non-teaching-staff">Details of Non-teaching Staff</NavDropdown.Item>
            <NavDropdown.Item href="/number-of-students">Number of Students</NavDropdown.Item>
            <NavDropdown.Item href="/books-list">Books List</NavDropdown.Item>
            <NavDropdown.Item href="/list-of-holidays">List of Holidays</NavDropdown.Item>
            <NavDropdown.Item href="/school-circulars">School Circulars</NavDropdown.Item>
            <NavDropdown.Item href="/fee-structure">Fee Structure</NavDropdown.Item>
            <NavDropdown.Item href="/no-homework-policy">No homework policy</NavDropdown.Item>
            <NavDropdown.Item href="/transfer-certificates">Transfer Certificates</NavDropdown.Item>
            <NavDropdown.Item href="/academic-calendar">Academic Calendar</NavDropdown.Item>              
          </NavDropdown>
          {/* <NavDropdown title="INFO CENTER" id="collasible-nav-dropdown">
            <NavDropdown.Item href="#action/3.1">Rules For Leave</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.1">Discipline</NavDropdown.Item>
            <NavDropdown.Item href="#action/3.1">Important Links</NavDropdown.Item>
          </NavDropdown> */}
          <NavDropdown title="Life@DBPASN" id="collasible-nav-dropdown">
            <NavDropdown.Item href="/student-council">Student Council</NavDropdown.Item>
            <NavDropdown.Item href="/clubs-and-committees">Clubs & Committees</NavDropdown.Item>
            <NavDropdown.Item href="/campus-area-and-classrooms">Infrastructure</NavDropdown.Item>
            {/* <NavDropdown.Item href="/trips-and-excursions">Trips & Excusrions</NavDropdown.Item>
            <NavDropdown.Item href="/celebrations">Celebrations</NavDropdown.Item> */}
          </NavDropdown>
          <NavDropdown title="Gallery" id="collasible-nav-dropdown">
            <NavDropdown.Item href="/photo-gallery">Photo Gallery</NavDropdown.Item>
            <NavDropdown.Item href="/media-gallery">Media Gallery</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Contact Us" id="collasible-tsnav-dropdown">
            <NavDropdown.Item href="/mail-us">Mail Us</NavDropdown.Item>
            <NavDropdown.Item href="/reach-us">Reach Us</NavDropdown.Item>
          </NavDropdown>
          <NavDropdown title="Mandatory Public Disclosures" id="collasible-nav-dropdown">
            <NavDropdown.Item href="/mandatory-public-disclosures">Documents</NavDropdown.Item>
            <NavDropdown.Item href="/annual-reports">Annual Reports</NavDropdown.Item>
            <NavDropdown.Item href="/affidavits">Affidavits</NavDropdown.Item>            
          </NavDropdown>
          <Nav.Link className="d-lg-none" href="/blogs">Blogs</Nav.Link>
          <Nav.Link className="d-lg-none" href="/photo-gallery">Photo Gallery</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
    </div>
  </header>
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

